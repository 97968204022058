import { AppConfig } from '@launchpad/types';

type ExtendedAppConfig = AppConfig & {
  flags: {
    showAdditionalReferences?: boolean;
    showTransactBalances?: boolean;
    showPinReminder: boolean;
    showPinButton?: boolean;
  };
};

const config: ExtendedAppConfig = {
  env: process.env.REACT_APP_ENVIRONMENT || 'dev',
  url: process.env.REACT_APP_URL || 'http://localhost',
  authStrategy: 'refresh-token',
  doNotMergeModules: true,
  notesCharLength: 500,
  policiesEnabled: true,
  modules: {
    fee: {
      partialRefundEnabled: true,
    },
    user: {
      editDisabled: false,
      createDisabled: false,
      deleteDisabled: false,
    },
  },
  flags: {
    showAdditionalReferences:
      process.env.REACT_APP_SHOW_ADDITIONAL_REFERENCES === 'true',
    showTransactBalances:
      process.env.REACT_APP_SHOW_TRANSACT_BALANCES === 'true',
    showPinButton: process.env.REACT_APP_TEMENOS_VARIANT === 'true',
    showPinReminder: false,
  },
  multipleTabsSession: process.env.REACT_APP_MULTIPLE_TABS_SESSION === 'true',
};

export default config;
